import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ResetStateAction, resetStateAction } from 'state/actions';

const useResetReduxState = (): (() => void) => {
  const dispatch = useDispatch<Dispatch<ResetStateAction>>();

  return useCallback(() => {
    dispatch(resetStateAction);
  }, [dispatch]);
};

export default useResetReduxState;
